import { useRef } from 'react'
import { Tab } from '@headlessui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ListingModel } from '@/models'
import { AwesomeIcon, SlideOver, SlideOverRef } from '@/components/ui'
import { Media } from '@/components/models/listing/page/manage/Media'

type ListingGalleryProps = {
  listing: ListingModel
  editMode?: boolean
  onUpdated?: () => void
  embedMode?: boolean
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const ListingGallery = ({
  listing,
  editMode,
  onUpdated,
  embedMode,
}: ListingGalleryProps) => {
  const slideOverRef = useRef<SlideOverRef>(null)

  if (!listing || !listing.item.media) return <></>
  else if (!editMode && listing.item.media.length === 0) return <></>
  return (
    <>
      {/* Image gallery */}
      <Tab.Group
        as="div"
        className={classNames(
          'flex flex-col-reverse bg-gradient-to-b from-white via-white to-bg-gray-50 rounded-lg',
          !embedMode ? 'p-6' : ''
        )}
      >
        {/* Image selector */}
        <div className="hidden mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
          <Tab.List
            className={classNames(
              'grid',
              embedMode ? 'grid-cols-5 gap-3' : 'grid-cols-8 gap-6'
            )}
          >
            {editMode && listing.item.media.length === 0 ? (
              <Tab
                key="editModeThumb"
                className="relative h-14 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-indigo-10 focus:outline-none focus:ring focus:ring-opacity-50"
              >
                {({ selected }) => (
                  <a
                    href="#"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.preventDefault()
                      if (slideOverRef.current) {
                        slideOverRef.current.openSlideOver()
                      }
                    }}
                  >
                    <span className="flex content-center align-middle overflow-hidden">
                      <AwesomeIcon
                        icon={regular('rectangle-history-circle-plus')}
                        className="h-6 text-indigo-500"
                      />
                    </span>
                    <span
                      className={classNames(
                        'ring-transparent',
                        'absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none'
                      )}
                      aria-hidden="true"
                    />
                  </a>
                )}
              </Tab>
            ) : (
              listing.item.media.map((item) => (
                <Tab
                  key={item.id}
                  className="relative h-14 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-indigo-10 focus:outline-none focus:ring focus:ring-opacity-50"
                >
                  {({ selected }) => (
                    <>
                      <span className="absolute inset-0 rounded-md overflow-hidden">
                        <img
                          src={
                            item.youTubeId
                              ? `https://i.ytimg.com/vi/${item.youTubeId}/hqdefault.jpg`
                              : `${process.env.NEXT_PUBLIC_SUPABASE_PUBLIC_STORAGE_URL}/listings/${listing.item.id}/${item.image}`
                          }
                          alt={item.id}
                          className="w-full h-full object-center object-cover"
                        />
                      </span>
                      <span
                        className={classNames(
                          selected ? 'ring-indigo-500' : 'ring-transparent',
                          'absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none'
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </Tab>
              ))
            )}
            {editMode && listing.item.media.length > 0 && (
              <button
                type="button"
                key="manage-media"
                className="relative h-14 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-indigo-10 focus:outline-none focus:ring focus:ring-opacity-50"
                onClick={() => {
                  if (slideOverRef.current) {
                    slideOverRef.current.openSlideOver()
                  }
                }}
              >
                <>
                  <span className="flex content-center align-middle overflow-hidden">
                    <AwesomeIcon
                      icon={regular('rectangle-history-circle-plus')}
                      className="h-6 text-indigo-500"
                    />
                  </span>
                  <span
                    className={classNames(
                      'ring-transparent',
                      'absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none'
                    )}
                    aria-hidden="true"
                  />
                </>
              </button>
            )}
          </Tab.List>
        </div>

        <Tab.Panels className="w-full">
          {({ selectedIndex }) => (
            <>
              {listing.item.media && listing.item.media.length > 0 ? (
                listing.item.media.map((item, itemIndex) => (
                  <Tab.Panel
                    key={item.id}
                    className={classNames(
                      'aspect-w-16 aspect-h-9 transition-opacity duration-700 ease-in-out rounded-lg overflow-hidden',
                      selectedIndex === itemIndex ? 'opacity-100' : 'opacity-0'
                    )}
                  >
                    <div className="absolute inset-0 shadow-inner rounded-lg z-10" />
                    {item.youTubeId ? (
                      <iframe
                        src={`https://www.youtube-nocookie.com/embed/${item.youTubeId}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    ) : (
                      <img
                        src={`${process.env.NEXT_PUBLIC_SUPABASE_PUBLIC_STORAGE_URL}/listings/${listing.item.id}/${item.image}`}
                        alt={item.id}
                        className={classNames('object-center object-cover')}
                      />
                    )}
                  </Tab.Panel>
                ))
              ) : editMode &&
                listing.item.media &&
                listing.item.media.length === 0 ? (
                <>
                  <Tab.Panel
                    key="editModeBlank"
                    className={classNames(
                      'aspect-w-16 aspect-h-9 transition-opacity duration-700 ease-in-out rounded-lg overflow-hidden',
                      'opacity-100 cursor-pointer'
                    )}
                    onClick={() => {
                      if (slideOverRef.current) {
                        slideOverRef.current.openSlideOver()
                      }
                    }}
                  >
                    <div className="absolute inset-0 shadow-inner rounded-lg z-10" />
                    <div className="object-center object-cover flex items-center justify-center">
                      <div className="text-gray-400 flex items-center flex-col">
                        <AwesomeIcon
                          icon={regular('rectangle-history-circle-plus')}
                          className="h-8 text-indigo-300 mb-8"
                        />
                        <span>
                          Click here to add marketing images to showcase your
                          product / brand.
                        </span>
                      </div>
                    </div>
                  </Tab.Panel>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </Tab.Panels>
      </Tab.Group>
      {editMode && (
        <SlideOver ref={slideOverRef} title="Listing Media" maxWidth="md">
          <Media listing={listing} onUpdated={onUpdated} />
        </SlideOver>
      )}
    </>
  )
}
export { ListingGallery }
