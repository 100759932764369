import { forwardRef, useState, useRef, useCallback, useEffect } from 'react'
import { useForm, useWatch, SubmitHandler } from 'react-hook-form'
import { regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAsyncEffect } from 'use-async-effect'
import { ReactSortable } from 'react-sortablejs'
import { supabase } from '@/utils/supabaseClient'
import { useApp } from '@/context'
import { request } from '@/utils/http'
import { concatFolderFilename } from '@/utils/image'
import {
  AwesomeIcon,
  ConfirmModal,
  ConfirmModalRef,
  Button,
} from '@/components/ui'
import { TextInput, ImageInput, RadioCards } from '@/components/form'
import { ListingModel, ListingMediaModel } from '@/models'
import { ListingMediaItem } from '@/utils/typings/models'

type FormInputs = {
  mediaType: string
  image: File | null
  youTubeId: string
}
type MediaProps = {
  listing?: ListingModel
  onUpdated?: () => void
}
export type MediaRef = {
  onSubmit: (onSuccess: () => void, onError: () => void) => void
}

const Media = forwardRef<MediaRef, MediaProps>((props, ref) => {
  const [items, setItems] = useState<ListingMediaItem[]>()
  const [item, setItem] = useState<ListingMediaItem | null>(null)
  const [processing, setProcessing] = useState<boolean>(false)
  const confirmModalDeleteRef = useRef<ConfirmModalRef>(null)
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormInputs>()
  const formValues = useWatch({ control })
  const { showError, showNotification } = useApp()
  let { listing, onUpdated } = props
  if (!listing) {
    listing = new ListingModel()
  }

  const resetItem = useCallback(() => {
    setItem(null)
    reset({ mediaType: '', image: null, youTubeId: '' })
  }, [reset])

  const refreshItems = async () => {
    if (listing?.item.id) {
      const _items = await ListingMediaModel.getListingMediaRaw(listing.item.id)
      if (_items) setItems(_items)
    }
  }

  const onSubmitForm: SubmitHandler<FormInputs> = async (
    formInputs
  ): Promise<boolean> => {
    setProcessing(true)

    if (listing) {
      let formData = new FormData()
      formData.append('mediaType', formInputs.mediaType)
      formData.append('image', formInputs.image as Blob)
      formData.append('youTubeId', formInputs.youTubeId)

      const res = await request({
        url: `/api/listings/${listing.item.id}/media/${
          item?.id ? `${item?.id}/update` : 'create'
        }`,
        method: item?.id ? 'PUT' : 'POST',
        params: formData,
        useFormData: true,
      })

      if (res) {
        await refreshItems()
        resetItem()
        if (onUpdated) onUpdated()
      } else {
        showError({ title: 'There was a problem saving the record' })
      }
    }

    setProcessing(false)
    return true
  }

  const onEditItem = (i: ListingMediaItem) => {
    setItem(i)
    reset({ mediaType: i.mediaType })
  }

  const onDeleteItem = (i: ListingMediaItem) => {
    if (confirmModalDeleteRef.current) {
      confirmModalDeleteRef.current.showModal(i)
    }
  }
  const onDeleteItemConfirm = async (i: ListingMediaItem) => {
    if (i.image && listing) {
      await supabase.storage
        .from('listings')
        .remove([concatFolderFilename(listing.item.id, i.image)])

      const mediaItem = new ListingMediaModel(i)
      await mediaItem.delete()
      await refreshItems()
      if (onUpdated) onUpdated()
      showNotification({ title: 'Media Deleted' })
    }
  }

  const onSaveOrder = async () => {
    if (items && listing) {
      let updatedOrder: { id: string; index: number }[] = []
      items.forEach((i: ListingMediaItem, index: number) => {
        updatedOrder.push({ id: i.id, index })
      })
      await request({
        url: `/api/listings/${listing.item.id}/media/update-order`,
        method: 'POST',
        params: { updatedOrder: updatedOrder },
      })
      if (onUpdated) onUpdated()
    }
  }

  useEffect(() => {
    resetItem()
  }, [resetItem])

  useAsyncEffect(async () => {
    if (listing?.item.id) {
      await refreshItems()
    }
  }, [listing])

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className="space-y-6 pt-6 pb-5">
        <div>
          <p className="mt-2 text-base">
            Upload promotional images or YouTube videos to help showcase and
            promote your listing.
          </p>
        </div>
        <div>
          <div className="bg-indigo-10 rounded-xl space-y-6 px-4 sm:px-6 pt-3 pb-4">
            <RadioCards
              name="mediaType"
              label="What type of media would you like to add?"
              columns={2}
              choices={[
                {
                  id: 'image',
                  title: 'Image',
                  icon: regular('image'),
                  iconClass: 'text-indigo-500',
                },
                {
                  id: 'youtube',
                  title: 'YouTube',
                  icon: brands('youtube'),
                  iconClass: 'text-red-500',
                },
              ]}
              value={
                formValues.mediaType ? formValues.mediaType : item?.mediaType
              }
              control={control}
              errors={errors}
              required={true}
            />
            {formValues.mediaType === 'image' && (
              <ImageInput
                label=""
                helpText="For best results, upload a 1792 x 1008 image."
                helpTextPosition="BOTTOM"
                bucket="listings"
                folder={listing.item.id}
                url={item?.image ? item.image : ''}
                mode="overlay"
                buttonClassName="rounded-md"
                autoUpload={false}
                onChange={(file: File) => {
                  setValue('image', file)
                }}
              />
            )}
            {formValues.mediaType === 'youtube' && (
              <div>
                <TextInput
                  name="youTubeId"
                  label="YouTube Video ID"
                  placeholder=""
                  value={item?.youTubeId}
                  register={register}
                  errors={errors}
                  required={true}
                  minLength={10}
                />
              </div>
            )}
            {formValues.mediaType && (
              <>
                <div className="flex items-center">
                  <div className="min-w-0 flex-1 flex flex-col sm:flex-row sm:space-y-0 sm:space-x-4 items-center">
                    <Button
                      text={
                        processing
                          ? 'Saving...'
                          : item?.id
                          ? 'Update Media'
                          : 'Add Media'
                      }
                      onClick={async (e: React.MouseEvent<HTMLElement>) => {
                        e.preventDefault()
                        await handleSubmit(
                          (formData) => {
                            onSubmitForm(formData)
                          },
                          () => {}
                        )()
                      }}
                    />
                    <Button
                      text="Cancel"
                      style="WHITE"
                      onClick={() => {
                        resetItem()
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <br />
          <>
            <h4 className="mb-2 text-sm font-semibold">Existing Media</h4>
            <div className="space-y-8 bg-white shadow sm:rounded-md overflow-hidden">
              {items && items.length > 0 ? (
                <ReactSortable
                  tag="ul"
                  list={items}
                  setList={setItems}
                  handle=".handle"
                  animation={150}
                  onEnd={async () => {
                    await onSaveOrder()
                  }}
                  className="divide-y divide-gray-200"
                >
                  {items.map((i: ListingMediaItem) => (
                    <li key={i.id}>
                      <div className="block hover:bg-indigo-10">
                        <div className="flex items-center">
                          <div className="min-w-0 flex-1 flex items-center p-1">
                            <img
                              src={
                                i.youTubeId
                                  ? `https://i.ytimg.com/vi/${i.youTubeId}/hqdefault.jpg`
                                  : `${process.env.NEXT_PUBLIC_SUPABASE_PUBLIC_STORAGE_URL}/listings/${listing?.item.id}/${i.image}`
                              }
                              alt={i.id}
                              className="object-cover h-12 w-16 rounded-md"
                            />
                          </div>
                          <div className="px-2 py-2">
                            <span className="relative z-0 inline-flex shadow-sm rounded-md">
                              <Button
                                text={
                                  <AwesomeIcon
                                    icon={regular('pencil')}
                                    className="h-3 text-gray-500"
                                  />
                                }
                                style="WHITE_MUTED"
                                className="relative rounded-l-md rounded-r-none"
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                  e.preventDefault()
                                  onEditItem(i)
                                }}
                              />
                              <Button
                                text={
                                  <AwesomeIcon
                                    icon={regular('trash')}
                                    className="h-3 text-gray-500"
                                  />
                                }
                                style="WHITE_MUTED"
                                className="-ml-px relative rounded-none"
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                  e.preventDefault()
                                  onDeleteItem(i)
                                }}
                              />
                              <Button
                                text={
                                  <AwesomeIcon
                                    icon={regular('grip-lines')}
                                    className="h-3 text-gray-500"
                                  />
                                }
                                style="WHITE_MUTED"
                                className="relative -ml-px rounded-l-md rounded-l-none handle"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ReactSortable>
              ) : (
                <div>
                  <span className="block text-gray-300 hover:bg-indigo-10 hover:text-gray-400">
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <p className="text-sm font-medium truncate">
                        No media found.
                      </p>
                    </div>
                  </span>
                </div>
              )}
            </div>
          </>
        </div>
      </div>
      <ConfirmModal
        ref={confirmModalDeleteRef}
        title="Are you sure?"
        buttonStyle="DANGER"
        description="To delete this media item, press the button below. Once deleted it cannot be recovered."
        onConfirm={onDeleteItemConfirm}
      />
    </form>
  )
})
export { Media }
