import { useRef } from 'react'
import { Disclosure } from '@headlessui/react'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ListingModel } from '@/models'
import { Button, AwesomeIcon, SlideOver, SlideOverRef } from '@/components/ui'
import { ListingFaqItem } from '@/utils/typings/models'
import { Faq } from '@/components/models/listing/page/manage/Faq'

type ListingFaqProps = {
  listing: ListingModel
  editMode: boolean
  onUpdated: () => void
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type EditModeButtonProps = {
  editMode: boolean
  slideOverRef: React.RefObject<SlideOverRef>
}

const EditModeButton = ({ editMode, slideOverRef }: EditModeButtonProps) => {
  if (!editMode) return <></>
  return (
    <div className="absolute -top-[84px] right-0 mb-4 flex justify-end">
      <Button
        text="Update FAQ Items"
        style="SECONDARY_ON_GRAY"
        size="XS"
        icon={regular('calendar-lines-pen')}
        onClick={() => {
          if (slideOverRef.current) {
            slideOverRef.current.openSlideOver()
          }
        }}
      />
    </div>
  )
}

const ListingFaq = ({ listing, editMode, onUpdated }: ListingFaqProps) => {
  const slideOverRef = useRef<SlideOverRef>(null)

  if (!listing || !listing.item.faq) return <></>
  return (
    <>
      <EditModeButton editMode={editMode} slideOverRef={slideOverRef} />
      {listing.item.faq.length === 0 ? (
        <div className="text-gray-400 text-sm py-3 px-4 bg-gray-100 rounded-lg">
          FAQ items have not been added to this listing yet. Please check back
          here later.
        </div>
      ) : (
        <dl className="space-y-3 divide-y divide-gray-200">
          {listing.item.faq.map((item: ListingFaqItem, index: number) => (
            <div key={item.id}>
              <Disclosure
                as="div"
                key={item.title}
                className={classNames(index > 0 ? 'pt-5' : '')}
              >
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900 text-sm">
                          {item.title}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <AwesomeIcon
                            icon={solid('chevron-down')}
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-4 relative -top-1 text-gray-700 transform'
                            )}
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-sm text-gray-500 whitespace-pre-line">
                        {item.description}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          ))}
        </dl>
      )}

      <SlideOver ref={slideOverRef} title="Listing FAQ" maxWidth="md">
        <Faq listing={listing} onUpdated={onUpdated} />
      </SlideOver>
    </>
  )
}
export { ListingFaq }
