import { useEffect, useState, ComponentType } from 'react'
import { useRouter } from 'next/router'
import { PageProps, LayoutProps, PageMetaProps } from '@/utils/typings/app'
import { useSession, useApp } from '@/context'

type PageWrapperProps = {
  pageTitle: string
  pageMeta?: PageMetaProps
  layout: LayoutProps
  supressErrors?: boolean
  sessionRequired?: boolean
  userRequired?: boolean
  walletRequired?: boolean
}

/* eslint-disable react-hooks/rules-of-hooks */
export function PageWrapper<P extends PageProps = PageProps>(
  Component: ComponentType<P>,
  props: PageWrapperProps
) {
  const {
    pageTitle,
    pageMeta,
    layout,
    supressErrors,
    sessionRequired,
    userRequired,
    walletRequired,
  } = props
  return ({ ...pageProps }: PageProps) => {
    const { setPageTitle, setPageMeta, setLayout, showError } = useApp()
    const { session, sessionLoaded } = useSession()
    const [accessDenied, setAccessDenied] = useState<boolean>(false)
    const router = useRouter()
    const [loading] = useState<boolean>(false)
    const { error } = pageProps

    useEffect(() => {
      if (error && error.title && !supressErrors) {
        showError({
          title: error.title,
          description: error.description,
        })
      }
    }, [error, showError])

    useEffect(() => {
      setLayout(layout)
      setPageTitle(pageTitle)
      if (pageMeta) setPageMeta(pageMeta)

      if (
        typeof window !== 'undefined' &&
        sessionLoaded &&
        ((sessionRequired && !session?.user && !session?.wallet) ||
          (userRequired && !session?.user) ||
          (walletRequired && !session?.wallet))
      ) {
        setAccessDenied(true)
        router.replace('/account/sign-in').catch(() => {})
      }
    }, [session, sessionLoaded, router, setLayout, setPageTitle, setPageMeta])

    if (loading) return <i>Loading...</i>
    if (accessDenied && !loading) return <i>Access denied, or not logged in.</i>
    return <Component {...(pageProps as P)} session={session} />
  }
}
/* eslint-enable react-hooks/rules-of-hooks */
