import { useState } from 'react'
import {
  regular,
  duotone,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { BrandModel, ListingModel } from '@/models'
import { AwesomeIcon, Tooltip } from '@/components/ui'
import { ImageInput } from '@/components/form'
import { ListingCTAs } from './ListingCTAs'

type ListingHeaderProps = {
  listing: ListingModel
  brand: BrandModel
  editMode: boolean
  onToggleEditMode: () => void
  onListingUpdated: (listing: ListingModel) => void
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const ListingHeader = ({
  listing,
  brand,
  editMode,
  onToggleEditMode,
  onListingUpdated,
}: ListingHeaderProps) => {
  const [headerImageUploading, setHeaderImageUploading] =
    useState<boolean>(false)
  const [logoImageUploading, setLogoImageUploading] = useState<boolean>(false)

  if (!listing) return <></>
  return (
    <>
      <div>
        <div className="relative group overflow-hidden">
          <img
            className="h-32 w-full object-cover lg:h-48"
            src={
              listing.item.pageHeaderImage
                ? `${process.env.NEXT_PUBLIC_SUPABASE_PUBLIC_STORAGE_URL}/listings/${listing.item.id}/${listing.item.pageHeaderImage}`
                : 'https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80'
            }
            alt=""
          />
          {editMode && (
            <>
              <div
                className={classNames(
                  'absolute inset-0 transition-opacity duration-300 ease-in-out group-hover:bg-gray-500 group-hover:opacity-75',
                  headerImageUploading ? 'opacity-100' : 'opacity-0'
                )}
              />
              <div
                className={classNames(
                  'absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center transition-opacity duration-300 ease-in-out group-hover:opacity-100',
                  headerImageUploading ? 'opacity-100' : 'opacity-0'
                )}
              >
                <ImageInput
                  bucket="listings"
                  folder={listing.item.id}
                  mode="button-only"
                  buttonText="Change Background"
                  buttonIcon={
                    headerImageUploading
                      ? duotone('spinner-third')
                      : regular('camera')
                  }
                  buttonIconClassName={
                    headerImageUploading ? 'h-4 fa-spin' : 'h-4'
                  }
                  buttonSize="MD"
                  buttonStyle="WHITE"
                  autoUpload={false}
                  onChange={async (file: File) => {
                    setHeaderImageUploading(true)
                    let formData = new FormData()
                    formData.append('pageHeaderImage', file)
                    const updatedListing = await listing.updatePage(formData)
                    if (updatedListing) onListingUpdated(updatedListing)
                    setHeaderImageUploading(false)
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="relative max-w-7xl mx-auto px-4 pt-[30px] sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex relative group">
              <img
                className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                src={
                  listing.item.pageLogoImage
                    ? `${process.env.NEXT_PUBLIC_SUPABASE_PUBLIC_STORAGE_URL}/listings/${listing.item.id}/${listing.item.pageLogoImage}`
                    : brand.logoUrlSquareFull
                }
                alt=""
              />
              {editMode && (
                <div className="absolute h-24 w-24 rounded-full sm:h-32 sm:w-32 overflow-hidden">
                  <div
                    className={classNames(
                      'absolute inset-0 transition-opacity duration-300 ease-in-out group-hover:bg-gray-500 group-hover:opacity-75',
                      logoImageUploading ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                  <div
                    className={classNames(
                      'absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center group-hover:opacity-100',
                      logoImageUploading ? 'opacity-100' : 'opacity-0'
                    )}
                  >
                    <ImageInput
                      bucket="listings"
                      folder={listing.item.id}
                      mode="button-only"
                      buttonText=""
                      buttonIcon={
                        logoImageUploading
                          ? duotone('spinner-third')
                          : regular('camera')
                      }
                      buttonIconClassName={
                        logoImageUploading ? 'h-4 fa-spin' : 'h-4'
                      }
                      buttonSize="MD"
                      buttonStyle="WHITE"
                      autoUpload={false}
                      onChange={async (file: File) => {
                        setLogoImageUploading(true)
                        let formData = new FormData()
                        formData.append('pageLogoImage', file)
                        const updatedListing = await listing.updatePage(
                          formData
                        )
                        if (updatedListing) onListingUpdated(updatedListing)
                        setLogoImageUploading(false)
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-end sm:justify-end sm:space-x-6">
              <div className="sm:hidden md:block mt-6 min-w-0 flex-1">
                <div className="text-sm font-medium text-gray-600 flex items-center">
                  <a
                    href={`/${listing.item.brand.slug}`}
                    className="underline text-gray-400 text-sm hover:text-gray-900"
                  >
                    {listing.item.brand.name}
                  </a>
                  {listing.item.brand.isVerified && (
                    <Tooltip
                      text="This brand has been verified"
                      position="top"
                      className=""
                    >
                      <AwesomeIcon
                        icon={duotone('circle-check')}
                        className="ml-1 text-green-500 text-xs"
                      />
                    </Tooltip>
                  )}
                </div>
                <h1 className="text-2xl font-bold text-gray-900 truncate">
                  {listing.item.name}
                </h1>
                <p className="text-sm font-medium text-gray-500">
                  {listing.item.strapline}
                </p>
              </div>
              <div className="justify-stretch mt-6 sm:flex-row">
                <ListingCTAs
                  listing={listing}
                  editMode={editMode}
                  onToggleEditMode={onToggleEditMode}
                />
              </div>
            </div>
          </div>
          <div className="hidden sm:block md:hidden mt-6 min-w-0 flex-1">
            <h1 className="text-2xl font-bold text-gray-900 truncate">
              {listing.item.name}
            </h1>
            <p className="text-sm font-medium text-gray-500">
              By{' '}
              <a href={`/${listing.item.brand.slug}`} className="default">
                {listing.item.brand.name}
              </a>{' '}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
export { ListingHeader }
