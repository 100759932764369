import { useState } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import TextareaAutosize from 'react-textarea-autosize'
import { ListingModel, CommentModel } from '@/models'
import { CommentItem } from '@/utils/typings/models'
import { Button, EllipsisMenu } from '@/components/ui'
import { useSession } from '@/context'
import { timeSince } from '@/utils/dates'

type ListingDiscussionProps = {
  listing: ListingModel
  onCommentPosted: (comment: CommentModel, totalComments: number) => void
  onCommentDeleted: (comment: CommentModel, totalComments: number) => void
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const ListingDiscussion = ({
  listing,
  onCommentPosted,
  onCommentDeleted,
}: ListingDiscussionProps) => {
  const { session, requestWallet, refreshSession } = useSession()
  const [comments, setComments] = useState<CommentModel[]>()
  const [commentsLoaded, setCommentsLoaded] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [commentBody, setCommentBody] = useState<string>('')
  const [commentReplyBody, setCommentReplyBody] = useState<string>('')
  const [commentBodyError, setCommentBodyError] = useState<boolean>(false)
  const [commentReplyBodyError, setCommentReplyBodyError] =
    useState<boolean>(false)
  const [totalVotes, setTotalVotes] = useState<{ [key: string]: number }>()
  const [voting, setVoting] = useState<number | null>(null)
  const [userHasVoted, setUserHasVoted] = useState<string[]>([])
  const [editingCommentId, setEditingCommentId] = useState<number | null>(null)
  const [replyToId, setReplyToId] = useState<number | null>(null)
  const [replyToActualId, setReplyToActualId] = useState<number | null>(null)

  const loadComments = async () => {
    const res = await CommentModel.getComments('listing', listing.item.id)
    if (res) {
      setComments(res.comments)
      setUserHasVoted(res.walletVotes)
    }
    // Get comments user has voted for
    setCommentsLoaded(true)
  }

  const onComment = async (reloadSession: boolean, replyMode: boolean) => {
    setSaving(true)
    if (replyToId) setCommentReplyBodyError(false)
    else setCommentBodyError(false)

    if (!commentReplyBody && replyToId && replyMode)
      setCommentReplyBodyError(true)
    else if (!commentBody && !replyToId && !replyMode) setCommentBodyError(true)
    else {
      let res: { comment: CommentModel; totalComments: number | null } | null =
        null
      if (reloadSession) {
        const _session = await refreshSession()
        res = await CommentModel.postComment(
          _session,
          'listing',
          listing.item.id,
          commentReplyBody && replyToId && replyMode
            ? commentReplyBody
            : commentBody,
          editingCommentId,
          replyToActualId
        )
      } else if (session) {
        res = await CommentModel.postComment(
          session,
          'listing',
          listing.item.id,
          commentReplyBody && replyToId && replyMode
            ? commentReplyBody
            : commentBody,
          editingCommentId,
          replyToActualId
        )
      }

      if (res && res.comment) {
        if (res.totalComments !== null)
          onCommentPosted(res.comment, res.totalComments)

        if (replyToId) setCommentReplyBody('')
        else setCommentBody('')

        if (replyToId) {
          setReplyToActualId(null)
          setReplyToId(null)
        }

        if (editingCommentId) setEditingCommentId(null)

        await loadComments()
      }
    }
    setSaving(false)
  }

  const onEditComment = (comment: CommentModel) => {
    setEditingCommentId(comment.item.id)

    if (replyToId) setCommentReplyBody(comment.item.body)
    else setCommentBody(comment.item.body)
  }

  const onDeleteComment = async (comment: CommentModel) => {
    const res = await comment.delete()
    if (res && res.success) {
      onCommentDeleted(comment, res.totalComments)
      await loadComments()
    }
  }

  const onVote = async (comment: CommentModel, reloadSession: boolean) => {
    setVoting(comment.item.id)
    let _totalVotes: number | null = null
    if (reloadSession) {
      const _session = await refreshSession()
      _totalVotes = await comment.analyticsVotesLog(
        _session,
        userHasVoted.includes(comment.item.id.toString())
      )
    } else if (session) {
      _totalVotes = await comment.analyticsVotesLog(
        session,
        userHasVoted.includes(comment.item.id.toString())
      )
    }

    if (_totalVotes !== null) {
      setTotalVotes({ ...totalVotes, ...{ [comment.item.id]: _totalVotes } })
      if (userHasVoted.includes(comment.item.id.toString()))
        setUserHasVoted(
          userHasVoted.filter((id) => id !== comment.item.id.toString())
        )
      else
        setUserHasVoted((hasVoted) => [
          ...hasVoted,
          ...[comment.item.id.toString()],
        ])
    }
    setVoting(null)
  }

  const renderForm = (commentId: number | null) => {
    return (
      <div className="flex space-x-3">
        {session?.wallet?.profileImage && (
          <div className="flex-shrink-0">
            <img
              className="h-10 w-10 rounded-lg bg-white"
              src={session.wallet.profileImage}
              alt={session.wallet.address}
            />
          </div>
        )}

        <div className="min-w-0 flex-1">
          <form action="#" className="w-full flex content-center">
            <div className="flex-1">
              <TextareaAutosize
                id="comment"
                name="comment"
                value={commentId ? commentReplyBody : commentBody}
                onChange={(e) => {
                  if (commentId) setCommentReplyBody(e.currentTarget.value)
                  else setCommentBody(e.currentTarget.value)
                }}
                rows={1}
                className={classNames(
                  'shadow-sm block w-full sm:text-sm border rounded-md',
                  commentBodyError && !commentId
                    ? 'border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                    : commentReplyBodyError && commentId
                    ? 'border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                    : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                )}
                placeholder="Join the discussion"
                maxRows={15}
              />
            </div>
            <div className="ml-3 flex-initial flex items-start justify-end">
              <Button
                text={saving ? 'Saving' : 'Comment'}
                className="inline-flex h-[38px]"
                showloadingIcon={saving}
                onClick={async () => {
                  if (!session?.wallet) {
                    requestWallet({
                      description:
                        'Comments are linked to your wallet. To connect your wallet now, please choose your preferred provider below.',
                      descriptionSuccess:
                        "Your wallet is now connected and we've submitted your comment. Thanks for joining in the discussion.",
                      onSuccess: async () => {
                        await onComment(true, commentId ? true : false)
                      },
                    })
                  } else await onComment(false, commentId ? true : false)
                }}
              />
            </div>
          </form>
        </div>
      </div>
    )
  }

  const renderComment = (comment: CommentModel) => {
    return (
      <div className="flex space-x-3 w-full">
        <div className="flex-shrink-0">
          <img
            className="h-10 w-10 rounded-lg bg-white"
            src={`${process.env.NEXT_PUBLIC_SUPABASE_PUBLIC_STORAGE_URL}/profile-images/${comment.item.walletUser.id}/${comment.item.walletUser.profileImage}`}
            alt={`Comment ${comment.item.id}`}
          />
        </div>
        <div className="w-full">
          <div className="text-sm">
            <a href="#" className="font-medium text-gray-900">
              {comment.item.walletUser.name
                ? comment.item.walletUser.name
                : `${comment.item.walletUser.id.slice(0, 16)}...`}
            </a>
          </div>
          <div className="mt-1 text-sm text-gray-700">
            <p className="whitespace-pre-line">{comment.item.body}</p>
          </div>
          <div className="mt-2 text-sm flex align-middle content-center justify-center space-x-2">
            <div className="flex-1 text-gray-500 font-medium text-xs inline-flex items-center">
              {timeSince(comment.item.postedAt)} ago
            </div>
            <div className="flex-initial flex">
              <Button
                text={`${
                  userHasVoted.includes(comment.item.id.toString())
                    ? 'Upvoted'
                    : 'Upvote'
                } (${
                  totalVotes && totalVotes[comment.item.id] !== null
                    ? totalVotes[comment.item.id]
                    : comment.item.totalVotes
                })`}
                showloadingIcon={voting === comment.item.id}
                icon={voting === comment.item.id ? null : solid('caret-up')}
                size="XS"
                style="TEXT"
                onClick={async () => {
                  if (!session?.wallet) {
                    requestWallet({
                      description:
                        'Votes are linked to your wallet. To connect your wallet now, please choose your preferred provider below.',
                      descriptionSuccess:
                        "Your wallet is now connected and we've logged your vote. Thanks for supporting this listing.",
                      onSuccess: async () => {
                        await onVote(comment, true)
                      },
                    })
                  } else await onVote(comment, false)
                }}
              />
              <Button
                text="Reply"
                size="XS"
                style="TEXT"
                className="ml-1"
                onClick={() => {
                  if (replyToId === comment.item.id) {
                    setReplyToActualId(null)
                    setReplyToId(null)
                  } else {
                    setReplyToActualId(
                      comment.item.replyToId
                        ? comment.item.replyToId
                        : comment.item.id
                    )
                    setReplyToId(comment.item.id)
                  }
                }}
              />
              {comment.item.walletUserId === session?.wallet?.address && (
                <EllipsisMenu
                  menuItems={[
                    {
                      title: 'Edit',
                      callback: () => {
                        onEditComment(comment)
                      },
                    },
                    {
                      title: 'Delete',
                      callback: async () => {
                        await onDeleteComment(comment)
                      },
                    },
                  ]}
                  className="ml-1 px-2.5 py-1.5 pb-1 text-xs font-medium rounded text-gray-700 focus:ring-indigo-500 hover:bg-indigo-50"
                />
              )}
            </div>
          </div>

          {replyToId === comment.item.id && (
            <div className="mt-2">{renderForm(comment.item.id)}</div>
          )}
        </div>
      </div>
    )
  }

  const renderReply = (reply: CommentItem) => {
    const comment = new CommentModel(reply)
    return (
      <div className="flex space-x-3 w-full mt-8">
        <div className="flex-shrink-0 w-10" />
        {renderComment(comment)}
      </div>
    )
  }

  useAsyncEffect(async () => {
    await loadComments()
  }, [])

  if (!listing || (!comments && !commentsLoaded))
    return (
      <>
        <p>Loading comments...</p>
      </>
    )
  return (
    <>
      <div className="">
        {renderForm(null)}

        {commentsLoaded && comments && comments.length === 0 ? (
          <p className="mt-8 text-gray-400 text-sm py-3 px-4 bg-gray-100 rounded-lg">
            This listing doesn't have any comments yet.{' '}
            <span className="underline decoration-sky-500 decoration-2">
              Be the first
            </span>{' '}
            to get the conversation started.
          </p>
        ) : comments ? (
          <ul className="space-y-8 mt-8">
            {comments.map((comment) => (
              <li key={comment.item.id}>
                {renderComment(comment)}
                {comment.item.replies.length > 0 && (
                  <>
                    {comment.item.replies.map((reply) => (
                      <div key={reply.id}>{renderReply(reply)}</div>
                    ))}
                  </>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
export { ListingDiscussion }

//
