import { NFTModel } from '@/models'
import { Pagination } from '@/components/ui'
import { PaginationOptions } from '@/utils/typings/db'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type NftListProps = {
  nfts: NFTModel[]
  mode?: 'NORMAL' | 'SIDEBAR'
  pagination?: PaginationOptions
  onPageChanged?: (page: number) => void
}

const NftList = ({
  nfts,
  mode = 'NORMAL',
  pagination,
  onPageChanged,
}: NftListProps) => {
  if (!nfts || nfts.length === 0) return <></>
  return (
    <>
      <div
        className={classNames(
          mode === 'SIDEBAR' ? '' : 'sm:grid-cols-2 lg:grid-cols-3',
          'grid grid-cols-1 gap-x-8 gap-y-8 sm:gap-y-10'
        )}
      >
        {nfts.map((nft) => (
          <div
            key={nft.item.id}
            className="relative group bg-white shadow rounded-lg"
          >
            <div className="pt-2 pr-2 pl-2">
              <div className="aspect-w-103 aspect-h-65 rounded-lg">
                {nft.item.nftImage && (
                  <img
                    src={nft.nftImageURL}
                    alt={`${nft.item.listing.name} #${nft.item.tokenIdLocal}`}
                    className="rounded-md"
                  />
                )}

                <div
                  className="flex items-end opacity-0 p-4 group-hover:opacity-100"
                  aria-hidden="true"
                >
                  <div className="w-full bg-white bg-opacity-75 backdrop-filter backdrop-blur py-2 px-4 rounded-md text-sm font-medium text-gray-900 text-center">
                    View NFT
                  </div>
                </div>
              </div>
            </div>
            <div className="py-5 px-4 sm:pt-6 sm:px-6">
              <h3 className="text-sm text-gray-800 font-medium mb-2 truncate">
                <a href={nft.relativeURL}>
                  <span aria-hidden="true" className="absolute inset-0" />#
                  {nft.item.tokenIdLocal}
                </a>
              </h3>
              <div className="mt-1 flex items-center justify-between text-xs text-gray-500 space-x-8">
                <p className="truncate">{nft.item.listing.name}</p>
                <p className="">{nft.item.price} ETH</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {pagination && (
        <Pagination pagination={pagination} onPageChanged={onPageChanged} />
      )}
    </>
  )
}
export { NftList }
