import { useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ListingModel, NFTModel } from '@/models'
import { Button, AwesomeIcon, SlideOver, SlideOverRef } from '@/components/ui'
import { PaginationOptions } from '@/utils/typings/db'
import { ListingFaqItem } from '@/utils/typings/models'
import { NftList } from '@/components/models/nft/page'
import { useAsyncEffect } from 'use-async-effect'

type ListingNFTsForSaleProps = {
  listing: ListingModel
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const ListingNFTsForSale = ({ listing }: ListingNFTsForSaleProps) => {
  const [nftsForSale, setNftsForSale] = useState<NFTModel[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pagination, setPagination] = useState<PaginationOptions>()

  useAsyncEffect(async () => {
    if (listing) {
      const forSale = await NFTModel.getNFTsForSale(listing.item.id)
      setNftsForSale(forSale)
    }
  }, [listing])

  const onPageChanged = (page: number) => {
    setCurrentPage(page)
  }

  if (!listing) return <></>
  return (
    <>
      <NftList
        nfts={nftsForSale}
        pagination={pagination}
        onPageChanged={onPageChanged}
      />
    </>
  )
}
export { ListingNFTsForSale }
