import { useState, useEffect } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { AwesomeIcon } from '@/components/ui'
import { ListingModel, ListingDiscountModel } from '@/models'
import { ListingPerkItem } from '@/utils/typings/models'
import { NotificationType } from '@/utils/types'
import { titleCase } from '@/utils/misc'
import { useApp } from '@/context'

type ListingPropertiesProps = {
  listing: ListingModel
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const SecondaryProperty = (
  name: string,
  value: string,
  icon: IconDefinition,
  infoModalTitle: string,
  infoModalContent: React.ReactNode,
  showNotification: (props: NotificationType) => void
) => {
  return (
    <div className="w-full">
      <a
        href="#"
        className="rounded-md py-1.5 mx-2 px-2 flex items-center relative group sm:px-3 sm:mx-3 hover:bg-indigo-10"
        onClick={() => {
          showNotification({
            title: infoModalTitle,
            description: infoModalContent,
            iconCustom: icon,
            style: 'MODAL',
          })
        }}
      >
        <div className="flex items-center justify-center h-6 w-6 mr-2 rounded-md text-sm bg-indigo-100">
          <AwesomeIcon icon={icon} className={classNames('text-indigo-500')} />
        </div>
        <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
          <span className="font-bold text-gray-700">{value}</span> {name}
        </p>
      </a>
    </div>
  )
}

const PrimaryProperty = (
  name: string,
  value: string,
  icon: IconDefinition,
  colour: string,
  infoModalTitle: string,
  infoModalContent: React.ReactNode,
  showNotification: (props: NotificationType) => void
) => {
  return (
    <a
      href="#"
      className="relative bg-white shadow rounded-lg overflow-hidden hover:bg-indigo-10"
      onClick={() => {
        showNotification({
          title: infoModalTitle,
          description: infoModalContent,
          iconCustom: icon,
          style: 'MODAL',
        })
      }}
    >
      <div className={classNames('absolute rounded-l-lg p-2', colour)}>
        <AwesomeIcon icon={icon} className="w-6 text-lg text-white" />
      </div>
      <div className="ml-12 py-1 pr-2">
        <p className="text-xs font-medium text-gray-500 truncate">{name}</p>
        <p className="text-xs font-bold text-gray-900">{value}</p>
      </div>
    </a>
  )
}

const ListingProperties = ({ listing }: ListingPropertiesProps) => {
  const [discount, setDiscount] = useState<ListingDiscountModel>()
  const { showNotification } = useApp()

  useEffect(() => {
    if (listing && listing.item.discounts && listing.item.discounts.length > 0)
      setDiscount(new ListingDiscountModel(listing.item.discounts[0]))
  }, [listing])

  if (
    !listing ||
    (listing &&
      !discount &&
      !listing.item.dividendPayoutPercentage &&
      listing.item.perks &&
      listing.item.perks.length === 0)
  )
    return <></>
  return (
    <>
      <div className="px-4 sm:pt-6 sm:px-6">
        <p className="text-xs font-medium text-gray-900 mb-1">Included</p>
      </div>
      <div className="flex flex-col items-start">
        {discount &&
          SecondaryProperty(
            titleCase(discount.discountDurationSummary),
            discount.discountValue,
            regular('tags'),
            'Discounts',
            `If purchased, the owner of this NFT will receive a ${discount.discountSummary}.`,
            showNotification
          )}
        {listing.item.dividendPayoutPercentage &&
          SecondaryProperty(
            'Dividend Payouts',
            `${listing.item.dividendPayoutPercentage.toString()}%`,
            regular('envelope-open-dollar'),
            'Dividend Payouts',
            'Add Content Here',
            showNotification
          )}
        {listing.item.perks &&
          listing.item.perks.length > 0 &&
          SecondaryProperty(
            `Exclusive Perk${listing.item.perks.length === 1 ? '' : 's'}`,
            listing.item.perks.length.toString(),
            regular('gift'),
            'Exclusive Perks',
            <>
              <p>
                {`If purchased, the owner of this NFT will be able to take advantage of the perk${
                  listing.item.perks.length === 1 ? '' : 's'
                } listed below.`}
              </p>
              <ul className="divide-y divide-gray-200">
                {listing.item.perks.map((perk: ListingPerkItem) => (
                  <li key={perk.title} className="py-4">
                    <p className="text-sm font-medium text-gray-900">
                      {perk.title}
                    </p>
                    <p className="text-sm text-gray-500">{perk.description}</p>
                  </li>
                ))}
              </ul>
            </>,
            showNotification
          )}
      </div>
    </>
  )
}
export { ListingProperties }
