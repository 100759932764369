import { useState } from 'react'
import { brands, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAsyncEffect } from 'use-async-effect'
import { Button, ButtonDropdown } from '@/components/ui'
import { ListingModel } from '@/models'
import { useSession } from '@/context'
import { windowOpen } from '@/utils/browser'

type ListingCTAsProps = {
  listing: ListingModel
  editMode: boolean
  onToggleEditMode: () => void
}

const ListingCTAs = ({
  listing,
  editMode,
  onToggleEditMode,
}: ListingCTAsProps) => {
  const { session, requestWallet, refreshSession } = useSession()
  const [totalVotes, setTotalVotes] = useState<number>(listing.item.totalVotes)
  const [voting, setVoting] = useState<boolean>(false)
  const [userHasVoted, setUserHasVoted] = useState<boolean>(false)

  const onVote = async (reloadSession: boolean) => {
    setVoting(true)
    let _totalVotes: number | null = null
    if (reloadSession) {
      const _session = await refreshSession()
      _totalVotes = await listing.analyticsVotesLog(_session, userHasVoted)
    } else if (session) {
      _totalVotes = await listing.analyticsVotesLog(session, userHasVoted)
    }

    if (_totalVotes !== null) {
      setTotalVotes(_totalVotes)
      setUserHasVoted(!userHasVoted)
    }
    setVoting(false)
  }

  useAsyncEffect(async () => {
    const voteStatus = await listing.analyticsVotesStatus()
    // const views = await listing.analyticsViewsStatus()
    await listing.analyticsViewsLog()
    setUserHasVoted(voteStatus.voted)
  }, [])

  if (!listing) return <></>
  return (
    <div className="flex gap-2 justify-end">
      {listing.item.brand.ownerId === session?.user?.id && (
        <Button
          text={editMode ? 'Exit Edit Mode' : 'Edit'}
          icon={editMode ? solid('arrow-right-from-bracket') : solid('pencil')}
          iconClassName="h-3"
          size="SM"
          style="SECONDARY_ON_GRAY"
          onClick={() => {
            onToggleEditMode()
          }}
        />
      )}
      <ButtonDropdown
        text="Share"
        style="SECONDARY_ON_GRAY"
        size="SM"
        buttons={[
          {
            text: 'Tweet',
            icon: brands('twitter-square'),
            iconClassName: 'text-twitter',
            onClick: (e: React.MouseEvent<HTMLElement>, item: ListingModel) => {
              e.preventDefault()
              windowOpen(
                `https://twitter.com/intent/tweet?text=${item.item.name} by ${
                  item.item.brand.name
                }${
                  item.item.strapline ? `: ${item.item.strapline}` : ''
                }&via=floor3&url=${item.absoluteURL}`,
                {
                  height: 400,
                  width: 550,
                  windowPosition: 'windowCenter',
                }
              )
            },
          },
          {
            text: 'Share',
            icon: brands('facebook-square'),
            iconClassName: 'text-facebook',
            onClick: (e: React.MouseEvent<HTMLElement>, item: ListingModel) => {
              e.preventDefault()
              windowOpen(
                `https://www.facebook.com/sharer/sharer.php?u=${item.absoluteURL}`,
                {
                  height: 400,
                  width: 550,
                  windowPosition: 'windowCenter',
                }
              )
            },
          },
        ]}
        item={listing}
      />
      <Button
        text={`${userHasVoted ? 'Upvoted' : 'Upvote'} ${totalVotes}`}
        showloadingIcon={voting}
        icon={voting ? undefined : solid('caret-up')}
        iconClassName="h-3.5 mt-[1px]"
        size="SM"
        style="PRIMARY"
        onClick={async () => {
          if (!session?.wallet) {
            requestWallet({
              description:
                'Votes are linked to your wallet. To connect your wallet now, please choose your preferred provider below.',
              descriptionSuccess:
                "Your wallet is now connected and we've logged your vote. Thanks for supporting this listing.",
              onSuccess: async () => {
                await onVote(true)
              },
            })
          } else await onVote(false)
        }}
      />
    </div>
  )
}
export { ListingCTAs }
