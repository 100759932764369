import dayjs from 'dayjs'
import { pluralize } from './misc'

export const displayDate = (date: Date, format?: string) => {
  if (!format) format = 'D MMMM YYYY'
  return dayjs(date).format(format)
}

export const convertEpochToDate = (epoch: number) => {
  let d = new Date(0)
  d.setUTCSeconds(epoch)
  return d
}

export const secondsSince = (date: Date) => {
  const seconds: number = Math.floor((Date.now() - date.getTime()) / 1000)
  return seconds
}

export const timeSince = (d: Date | string) => {
  let date: Date = typeof d === 'string' ? new Date(d) : d

  const seconds: number = Math.floor((Date.now() - date.getTime()) / 1000)

  let interval = seconds / 31_536_000

  if (interval > 1) {
    return `${Math.floor(interval)} ${pluralize('year', Math.floor(interval))}`
  }
  interval = seconds / 2_592_000
  if (interval > 1) {
    return `${Math.floor(interval)} ${pluralize('month', Math.floor(interval))}`
  }
  interval = seconds / 86_400
  if (interval > 1) {
    return `${Math.floor(interval)} ${pluralize('day', Math.floor(interval))}`
  }
  interval = seconds / 3600
  if (interval > 1) {
    return `${Math.floor(interval)} ${pluralize('hour', Math.floor(interval))}`
  }
  interval = seconds / 60
  if (interval > 1) {
    return `${Math.floor(interval)} ${pluralize(
      'minute',
      Math.floor(interval)
    )}`
  }
  return `${Math.floor(seconds)} ${pluralize('second', Math.floor(interval))}`
}

export const addDaysToDate = (date: Date, days: number): Date => {
  const newDate = new Date(date.valueOf())
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

export const subtractDaysFromDate = (date: Date, days: number): Date => {
  const newDate = new Date(date.valueOf())
  newDate.setDate(newDate.getDate() - days)
  return newDate
}

export const getFirstDayOfWeek = (d: Date) => {
  const day = d.getDay()
  const diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
  return new Date(d.setDate(diff))
}

export function dateRange(
  start: Date | number,
  end: Date | number,
  interval:
    | 'millisecond'
    | 'second'
    | 'minute'
    | 'hour'
    | 'day'
    | 'week'
    | 'month'
    | 'year',
  asUnixTimestampsMS = false
): (number | Date)[] {
  const startDate = dayjs(start)
  const endDate = dayjs(end)
  const diffInUnits = endDate.diff(startDate, interval)
  return [...Array.from({ length: diffInUnits + 1 }).keys()].map((i) => {
    // eslint-disable-line unicorn/new-for-builtins
    return asUnixTimestampsMS
      ? startDate.add(i, interval).valueOf()
      : startDate.add(i, interval).toDate()
  })
}
