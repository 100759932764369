import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAsyncEffect } from 'use-async-effect'
import { ButtonDropdown } from '@/components/ui'
import { NFTModel } from '@/models'
import { windowOpen } from '@/utils/browser'

type NftCTAsProps = {
  nft: NFTModel
  editMode: boolean
  onToggleEditMode: () => void
}

const NftCTAs = ({ nft }: NftCTAsProps) => {
  useAsyncEffect(async () => {
    await nft.analyticsViewsLog()
  }, [])

  if (!nft) return <></>
  return (
    <div className="flex gap-2 justify-end">
      <ButtonDropdown
        text="Share"
        style="SECONDARY_ON_GRAY"
        size="SM"
        buttons={[
          {
            text: 'Tweet',
            icon: brands('twitter-square'),
            iconClassName: 'text-twitter',
            onClick: (e: React.MouseEvent<HTMLElement>, item: NFTModel) => {
              e.preventDefault()
              windowOpen(
                `https://twitter.com/intent/tweet?text=${
                  item.item.listing.name
                } by ${item.item.listing.brand.name}${
                  item.item.listing.strapline
                    ? `: ${item.item.listing.strapline}`
                    : ''
                }&via=floor3&url=${item.absoluteURL}`,
                {
                  height: 400,
                  width: 550,
                  windowPosition: 'windowCenter',
                }
              )
            },
          },
          {
            text: 'Share',
            icon: brands('facebook-square'),
            iconClassName: 'text-facebook',
            onClick: (e: React.MouseEvent<HTMLElement>, item: NFTModel) => {
              e.preventDefault()
              windowOpen(
                `https://www.facebook.com/sharer/sharer.php?u=${item.absoluteURL}`,
                {
                  height: 400,
                  width: 550,
                  windowPosition: 'windowCenter',
                }
              )
            },
          },
        ]}
        item={nft}
      />
    </div>
  )
}
export { NftCTAs }
