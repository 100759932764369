import { forwardRef, useState, useRef, useCallback, useEffect } from 'react'
import { useForm, useWatch, SubmitHandler } from 'react-hook-form'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAsyncEffect } from 'use-async-effect'
import { ReactSortable } from 'react-sortablejs'
import { useApp } from '@/context'
import { request } from '@/utils/http'
import {
  AwesomeIcon,
  ConfirmModal,
  ConfirmModalRef,
  Button,
} from '@/components/ui'
import { TextInput } from '@/components/form'
import { ListingModel, ListingFaqModel } from '@/models'
import { ListingFaqItem } from '@/utils/typings/models'

type FormInputs = {
  title: string
  description: string
}
type FaqProps = {
  listing?: ListingModel
  onUpdated: () => void
}
export type FaqRef = {
  onSubmit: (onSuccess: () => void, onError: () => void) => void
}

const Faq = forwardRef<FaqRef, FaqProps>((props, ref) => {
  const [items, setItems] = useState<ListingFaqItem[]>()
  const [item, setItem] = useState<ListingFaqItem | null>(null)
  const [processing, setProcessing] = useState<boolean>(false)
  const confirmModalDeleteRef = useRef<ConfirmModalRef>(null)
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormInputs>()
  const formValues = useWatch({ control })
  const { showError, showNotification } = useApp()
  let { listing, onUpdated } = props
  if (!listing) {
    listing = new ListingModel()
  }

  const resetItem = useCallback(() => {
    setItem(null)
    reset({ title: '', description: '' })
  }, [reset])

  const refreshItems = async () => {
    if (listing?.item.id) {
      const _items = await ListingFaqModel.getListingFaqRaq(listing.item.id)
      if (_items) setItems(_items)
    }
  }

  const onSubmitForm: SubmitHandler<FormInputs> = async (
    formInputs
  ): Promise<boolean> => {
    setProcessing(true)

    if (listing) {
      const res = await request({
        url: `/api/listings/${listing.item.id}/faq/${
          item?.id ? `${item?.id}/update` : 'create'
        }`,
        method: item?.id ? 'PUT' : 'POST',
        params: {
          title: formInputs.title,
          description: formInputs.description,
        },
      })

      if (res) {
        await refreshItems()
        resetItem()
        onUpdated()
      } else {
        showError({ title: 'There was a problem saving the record' })
      }
    }

    setProcessing(false)
    return true
  }

  const onEditItem = (i: ListingFaqItem) => {
    setItem(i)
    reset({ title: i.title, description: i.description })
  }

  const onDeleteItem = (i: ListingFaqItem) => {
    if (confirmModalDeleteRef.current) {
      confirmModalDeleteRef.current.showModal(i)
    }
  }
  const onDeleteItemConfirm = async (i: ListingFaqItem) => {
    if (i && listing) {
      const faqItem = new ListingFaqModel(i)
      await faqItem.delete()
      await refreshItems()
      onUpdated()
      showNotification({ title: 'FAQ Item Deleted' })
    }
  }

  const onSaveOrder = async () => {
    if (items && listing) {
      let updatedOrder: { id: string; index: number }[] = []
      items.forEach((i: ListingFaqItem, index: number) => {
        updatedOrder.push({ id: i.id, index })
      })
      await request({
        url: `/api/listings/${listing.item.id}/faq/update-order`,
        method: 'POST',
        params: { updatedOrder: updatedOrder },
      })
      onUpdated()
    }
  }

  useEffect(() => {
    resetItem()
  }, [resetItem])

  useAsyncEffect(async () => {
    if (listing?.item.id) {
      await refreshItems()
    }
  }, [listing])

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className="space-y-6 pt-6 pb-5">
        <div>
          <p className="mt-2 text-base">
            Add FAQ items to help explain your listing and answer common
            questions.
          </p>
        </div>
        <div>
          <div className="bg-indigo-10 rounded-xl space-y-6 px-4 sm:px-6 pt-3 pb-4">
            <div>
              <TextInput
                name="title"
                label="Title"
                placeholder=""
                value={item?.title}
                register={register}
                errors={errors}
                required={true}
              />
            </div>
            <div>
              <TextInput
                name="description"
                label="Description"
                type="textarea-autoresize"
                placeholder=""
                value={item?.description}
                control={control}
                register={register}
                errors={errors}
                required={true}
              />
            </div>

            <>
              <div className="flex items-center">
                <div className="min-w-0 flex-1 flex flex-col sm:flex-row sm:space-y-0 sm:space-x-4 items-center">
                  <Button
                    text={
                      processing
                        ? 'Saving...'
                        : item?.id
                        ? 'Update FAQ Item'
                        : 'Add FAQ Item'
                    }
                    onClick={async (e: React.MouseEvent<HTMLElement>) => {
                      e.preventDefault()
                      await handleSubmit(
                        (formData) => {
                          onSubmitForm(formData)
                        },
                        () => {}
                      )()
                    }}
                  />
                  {item?.id && (
                    <Button
                      text="Cancel"
                      style="WHITE"
                      onClick={() => {
                        resetItem()
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          </div>
          <br />
          <>
            <h4 className="mb-2 text-sm font-semibold">Existing FAQ Items</h4>
            <div className="bg-white shadow sm:rounded-md overflow-hidden">
              {items && items.length > 0 ? (
                <ReactSortable
                  tag="ul"
                  list={items}
                  setList={setItems}
                  handle=".handle"
                  animation={150}
                  onEnd={async () => {
                    await onSaveOrder()
                  }}
                  className="divide-y divide-gray-200"
                >
                  {items.map((i: ListingFaqItem) => (
                    <li key={i.id}>
                      <div className="block hover:bg-indigo-10">
                        <div className="flex items-center">
                          <div className="min-w-0 flex-1 flex items-center p-1 pl-2">
                            <span className="truncate text-sm text-gray-700">
                              {i.title}
                            </span>
                          </div>
                          <div className="px-2 py-2">
                            <span className="relative z-0 inline-flex shadow-sm rounded-md">
                              <Button
                                text={
                                  <AwesomeIcon
                                    icon={regular('pencil')}
                                    className="h-3 text-gray-500"
                                  />
                                }
                                style="WHITE_MUTED"
                                className="relative rounded-l-md rounded-r-none"
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                  e.preventDefault()
                                  onEditItem(i)
                                }}
                              />
                              <Button
                                text={
                                  <AwesomeIcon
                                    icon={regular('trash')}
                                    className="h-3 text-gray-500"
                                  />
                                }
                                style="WHITE_MUTED"
                                className="-ml-px relative rounded-none"
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                  e.preventDefault()
                                  onDeleteItem(i)
                                }}
                              />
                              <Button
                                text={
                                  <AwesomeIcon
                                    icon={regular('grip-lines')}
                                    className="h-3 text-gray-500"
                                  />
                                }
                                style="WHITE_MUTED"
                                className="relative -ml-px rounded-l-md rounded-l-none handle"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ReactSortable>
              ) : (
                <div>
                  <span className="block text-gray-300 hover:bg-indigo-10 hover:text-gray-400">
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <p className="text-sm font-medium truncate">
                        No FAQ items found.
                      </p>
                    </div>
                  </span>
                </div>
              )}
            </div>
          </>
        </div>
      </div>
      <ConfirmModal
        ref={confirmModalDeleteRef}
        title="Are you sure?"
        buttonStyle="DANGER"
        description="To delete this FAQ item, press the button below. Once deleted it cannot be recovered."
        onConfirm={onDeleteItemConfirm}
      />
    </form>
  )
})
export { Faq }
