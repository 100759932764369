import { useEffect } from 'react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AwesomeIcon, Tooltip } from '@/components/ui'
import { ListingModel } from '@/models'
import { numberKMFormatter, numberWithCommas } from '@/utils/misc'
import { timeSince, displayDate } from '@/utils/dates'
import { ListingGallery } from './ListingGallery'

type ListingEmbedAboutProps = {
  listing: ListingModel
}

const ListingEmbedAbout = ({ listing }: ListingEmbedAboutProps) => {
  useEffect(() => {}, [listing])

  if (!listing) return <></>
  return (
    <>
      <div className="relative group bg-white rounded-lg py-5 px-4 sm:px-6">
        <div className="grid grid-cols-2 gap-x-4">
          <div className="relative">
            <h3 className="text-xs font-medium text-indigo-500 mb-1">
              About this listing
            </h3>
            <p className="text-sm text-gray-600">{listing.item.description}</p>
            <div className="flex gap-x-4 absolute bottom-0 left-0">
              <Tooltip
                text={`This listing has a limited supply of ${numberWithCommas(
                  listing.item.supply
                )} NFT's.`}
                position="top"
                className=""
              >
                <span className="flex items-center justify-center h-11 px-3 gap-x-2 rounded-md bg-indigo-25 relative">
                  <AwesomeIcon
                    icon={solid('box-open')}
                    className="text-indigo-300"
                  />
                  <span className="text-indigo-500 text-xs font-semibold">
                    {numberKMFormatter(listing.item.supply)}
                  </span>
                </span>
              </Tooltip>
              {listing.item.publishedAt && (
                <Tooltip
                  text={`This listing first launched ${timeSince(
                    listing.item.publishedAt
                  )} ago.`}
                  position="top"
                  className=""
                >
                  <span className="flex items-center justify-center h-11 px-3 gap-x-2 rounded-md bg-indigo-25 relative">
                    <AwesomeIcon
                      icon={solid('calendar')}
                      className="text-indigo-300"
                    />
                    <span className="text-indigo-500 text-xs font-semibold">
                      {displayDate(listing.item.publishedAt, 'MMM YYYY')}
                    </span>
                  </span>
                </Tooltip>
              )}

              <a href={listing.relativeURL} className="group">
                <div className="flex items-center justify-center h-11 px-3 gap-x-2">
                  <span className="text-gray-400 text-xs font-medium group-hover:text-gray-700">
                    View Full Listing
                  </span>
                  <AwesomeIcon
                    icon={solid('angle-right')}
                    className="text-gray-300 group-hover:text-gray-500"
                  />
                </div>
              </a>
            </div>
          </div>
          <div>
            <ListingGallery listing={listing} embedMode={true} />
          </div>
        </div>
      </div>
    </>
  )
}
export { ListingEmbedAbout }
